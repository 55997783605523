import parseSvgPath from 'parse-svg-path';
import absSvgPath from 'abs-svg-path';
import normalizeSvgPath from 'normalize-svg-path';

export const INTENSITY = 10;
export const ANIM_DURATION = 1500;

export const getSegmentsFromPathElement = path =>
  normalizeSvgPath(absSvgPath(parseSvgPath(path.getAttribute('d'))));

export const getRoughSurface = shape => {
  let minY = Infinity;
  let maxY = -Infinity;
  let minX = Infinity;
  let maxX = -Infinity;
  let point;

  shape.forEach(segment => {
    for (let i = 1; i < segment.length; i++) {
      point = segment[i];
      if (i % 2 === 0) {
        minY = Math.min(minY, point);
        maxY = Math.max(maxY, point);
      } else {
        minX = Math.min(minX, point);
        maxX = Math.max(maxX, point);
      }
    }
  });

  return (maxX - minX) * (maxY - minY);
};

export const translatePath = (path, dx, dy) =>
  path.forEach(segment =>
    segment.forEach((point, i) => {
      if (i === 0) return;
      segment[i] += i % 2 === 0 ? dy : dx;
    }),
  );

const animatePointInPath = (segments, i, intensity) => {
  const segmentCount = segments.length;

  const dx = Math.random() * intensity - intensity / 2;
  const dy = Math.random() * intensity - intensity / 2;

  if (i === segmentCount - 1) {
    segments[0][1] = segments[i - 1][5];
    segments[0][2] = segments[i - 1][6];
    segments[i][1] = segments[i - 1][5];
    segments[i][2] = segments[i - 1][6];
    segments[i][3] = segments[i - 1][5];
    segments[i][4] = segments[i - 1][6];
    segments[i][5] = segments[i - 1][5];
    segments[i][6] = segments[i - 1][6];
  } else {
    segments[i][3] += dx;
    segments[i][4] += dy;

    segments[i][5] += dx;
    segments[i][6] += dy;

    segments[i + 1][1] += dx;
    segments[i + 1][2] += dy;

    if (i === segmentCount - 2) {
      segments[1][1] += dx;
      segments[1][2] += dy;
    }
  }
};

export const animate = (path, segments, surface) => {
  // clone original segments
  const newSegments = segments.map(s => s.slice(0)).slice(0);
  const segmentCount = newSegments.length;

  const currentSegments = getSegmentsFromPathElement(path);
  const ignoreStart = Math.random() * (segmentCount - 2);
  const ignoreEnd = ignoreStart + Math.random() * (segmentCount / 2);

  for (let i = 1; i < segmentCount; i++) {
    if (i >= ignoreStart && i <= ignoreEnd && i < segmentCount - 2) {
      newSegments[i][3] = currentSegments[i][3];
      newSegments[i][4] = currentSegments[i][4];
      newSegments[i][5] = currentSegments[i][5];
      newSegments[i][6] = currentSegments[i][6];
      newSegments[i + 1][1] = currentSegments[i + 1][1];
      newSegments[i + 1][2] = currentSegments[i + 1][2];
      continue;
    }

    animatePointInPath(newSegments, i, INTENSITY * (surface / 8000) + INTENSITY / 4);
  }

  translatePath(newSegments, 0, Math.random() * 10 - 10 / 2);

  path.setAttribute('d', newSegments.flat().join(' '));
};
