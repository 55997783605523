import React, { useEffect, useRef } from 'react';

import {
  getSegmentsFromPathElement,
  translatePath,
  ANIM_DURATION,
  animate,
  getRoughSurface,
} from './animate';

const Blobs = () => {
  const svg = useRef(null);

  useEffect(() => {
    if (!svg) return;

    let delay = 0;

    const timeouts = [];
    const intervals = [];

    const paths = svg.current.querySelectorAll('path');
    paths.forEach(path => {
      const segments = getSegmentsFromPathElement(path);

      translatePath(segments, 0.01, 0.01);
      path.setAttribute('d', segments.flat().join(' '));
      path.setAttribute(
        'style',
        `transition: d ${ANIM_DURATION}ms cubic-bezier(0.455, 0.03, 0.515, 0.955)`,
      );

      timeouts.push(
        setTimeout(
          () =>
            intervals.push(
              setInterval(
                animate.bind(null, path, segments, getRoughSurface(segments)),
                ANIM_DURATION,
              ),
            ),
          delay,
        ),
      );

      delay += ANIM_DURATION / paths.length;
    });

    return () => {
      timeouts.forEach(t => clearTimeout(t));
      intervals.forEach(t => clearInterval(t));
    };
  }, [svg]);

  return (
    <svg
      width="510"
      height="228"
      viewBox="0 0 393 176"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svg}
    >
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M175.526 114.826C166.094 121.538 142.486 130.748 123.507 113.902C111.307 102.061 126.494 98.6689 130.334 84.2643C134.175 69.8597 122.227 23.9666 163.953 58.8704C197.333 86.7933 185.577 107.809 175.526 114.826Z"
          fill="url(#paint0_linear)"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M261.599 93.7008C252.17 96.6363 229.936 99.5566 216.434 87.7534C207.936 79.5757 221.782 79.1754 227.725 71.1693C233.668 63.1632 231.528 35.1419 261.599 59.8464C285.655 79.61 271.622 90.6508 261.599 93.7008Z"
          fill="url(#paint1_linear)"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M251.047 60.8396C253.147 76.7006 240.175 83.7548 222.939 83.8757C202.563 84.0187 179.8 79.7443 163.79 71.5775C143.308 61.1295 144.314 48.7126 144.603 46.519C146.314 33.5069 164.808 25.7389 198.875 28.4499C236.318 31.4295 247.353 47.0544 251.047 60.8396Z"
          fill="url(#paint2_linear)"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M106.812 76.1085C107.075 80.6311 104.533 82.8239 101.238 83.0833C97.3418 83.39 93.0284 82.4606 90.0401 80.3265C86.217 77.5964 86.5185 74.0213 86.5929 73.3882C87.0343 69.6331 90.6375 67.1636 97.1251 67.4971C104.256 67.8637 106.228 72.2021 106.812 76.1085Z"
          fill="url(#paint3_linear)"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M189.977 106.962C196.97 127.172 193.663 140.127 185.755 145.203C176.407 151.203 164.28 152.089 153.79 145.941C140.371 138.077 136.12 121.499 135.42 118.538C131.268 100.978 136.852 85.511 153.602 79.3496C172.011 72.5776 183.037 89.9292 189.977 106.962Z"
          fill="url(#paint4_linear)"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.9">
        <path
          d="M299.915 117.924C294.707 124.25 289.811 125.759 286.571 124.189C282.741 122.333 280.027 118.546 279.94 113.888C279.828 107.93 284.397 103.226 285.224 102.405C290.134 97.5378 296.306 96.2709 301.669 100.514C307.562 105.177 304.095 112.25 299.915 117.924Z"
          fill="url(#paint5_linear)"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M85.6634 122.813C81.8469 124.001 72.8475 125.183 67.3825 120.406C63.9428 117.096 69.5473 116.934 71.9527 113.693C74.3581 110.453 73.4922 99.1109 85.6634 109.11C95.4005 117.11 89.7205 121.579 85.6634 122.813Z"
          fill="url(#paint6_linear)"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M249.866 132.987C235.57 146.028 224.035 148.18 217.505 143.754C209.785 138.521 205.375 129.184 207.18 118.552C209.488 104.951 221.667 95.4371 223.86 93.7853C236.866 83.9873 251.135 82.7217 261.238 93.7936C272.342 105.962 261.596 121.162 249.866 132.987Z"
          fill="url(#paint7_linear)"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M315.916 87.4451C313.659 89.6839 311.518 89.6437 310.091 88.3756C308.404 86.8764 307.198 84.6194 307.139 82.3545C307.065 79.4569 309.048 77.9016 309.408 77.6351C311.54 76.0542 314.241 76.4121 316.611 79.3059C319.215 82.4865 317.724 85.36 315.916 87.4451Z"
          fill="url(#paint8_linear)"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M356.743 108.854C357.052 111.979 355.704 113.598 353.864 113.906C351.689 114.27 349.235 113.793 347.482 112.43C345.239 110.686 345.281 108.196 345.3 107.754C345.413 105.134 347.347 103.282 351 103.261C355.014 103.239 356.276 106.169 356.743 108.854Z"
          fill="url(#paint9_linear)"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M31.1046 100.806C27.8827 100.814 26.2625 99.6175 26.0056 98.1293C25.7019 96.3701 26.2712 94.453 27.729 93.1612C29.5941 91.5084 32.1521 91.7309 32.6055 91.7797C35.2949 92.0695 37.1358 93.7498 37.0391 96.659C36.9328 99.8564 33.8797 100.638 31.1046 100.806Z"
          fill="url(#paint10_linear)"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M234.653 140.5C239.307 154 219.827 163.468 201.827 162.468C183.827 161.468 169 152.633 169 140.5C169 128.367 183.5 123 201.827 118.532C220.153 114.064 230 127 234.653 140.5Z"
          fill="url(#paint11_linear)"
          fillOpacity="0.5"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="157.29"
          y1="122.555"
          x2="143.55"
          y2="45.4267"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5DCB36" />
          <stop offset="1" stopColor="#33FAED" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="223.405"
          y1="82.7322"
          x2="282.437"
          y2="82.1234"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A226CD" />
          <stop offset="1" stopColor="#7115E6" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="144.471"
          y1="47.8712"
          x2="251.947"
          y2="57.6154"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00D1FF" />
          <stop offset="1" stopColor="#7D2AE7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="86.5558"
          y1="73.7778"
          x2="106.971"
          y2="75.6288"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2AA3E7" />
          <stop offset="1" stopColor="#8FFF00" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="136"
          y1="129.5"
          x2="166.666"
          y2="133.824"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.22541" stopColor="#FFD600" />
          <stop offset="1" stopColor="#9BB10F" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="289.115"
          y1="100.424"
          x2="284.542"
          y2="121.581"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6B00" />
          <stop offset="1" stopColor="#7D0FB1" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="70.2042"
          y1="118.374"
          x2="94.0977"
          y2="118.127"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5ED19" />
          <stop offset="1" stopColor="#3DDA23" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="259.162"
          y1="90.1306"
          x2="242.519"
          y2="143.094"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5C00" />
          <stop offset="1" stopColor="#B10F0F" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="316.071"
          y1="78.4051"
          x2="311.139"
          y2="87.2608"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAFF00" />
          <stop offset="1" stopColor="#FFBEBE" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="349.984"
          y1="103.116"
          x2="353.748"
          y2="112.374"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2E96F5" />
          <stop offset="1" stopColor="#1DCA43" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="37.2213"
          y1="95.8618"
          x2="28.4268"
          y2="99.9612"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB00FF" />
          <stop offset="1" stopColor="#FFA24D" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="221.831"
          y1="138.496"
          x2="173.211"
          y2="140.434"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5669AB" />
          <stop offset="1" stopColor="#88A7C4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Blobs;
