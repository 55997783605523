import React from 'react';

import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Wrapper from '../components/Wrapper';
import Highlight from '../components/Highlight';
import Blobs from '../components/Blobs';
import Gallery from '../components/Gallery';

import LinkedinIcon from '../images/logos/linkedin.svg';
import GithubIcon from '../images/logos/github.svg';
import TwitterIcon from '../images/logos/twitter.svg';
import EmailIcon from '../images/logos/email.svg';
import PhoneIcon from '../images/logos/phone.svg';

import styles from './index.module.css';

const Period = ({ from, to, duration }) => (
  <div className={styles.PeriodWrapper}>
    <div className={styles.Period}>
      <div className={styles.Duration}>{duration}</div>
      <div className={styles.From}>{from}</div>
      <div className={styles.To}>{to}</div>
    </div>
  </div>
);

const ExtLink = ({ href, children, ...other }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" {...other}>
    {children}
  </a>
);

const IndexPage = ({ data }) => {
  return (
    <Layout className={styles.Homepage}>
      <Seo title="Home" />
      <Highlight bottomShadow>
        <div className={styles.ProfilePictureContainer}>
          <Blobs />
          <div className={styles.ProfilePicture} />
        </div>
        <Wrapper className={styles.Header}>
          <header>
            <h1>
              // Arnaud Didry
              <div className={styles.Subtitle}>10+ ans d'expérience en développement web</div>
            </h1>
            <div className={styles.Links}>
              <ExtLink href="https://www.linkedin.com/in/arnauddidry/" alt="LinkedIn Profile">
                <LinkedinIcon />
              </ExtLink>
              <ExtLink href="https://github.com/ArnaudD" alt="Github Profile">
                <GithubIcon />
              </ExtLink>
              <ExtLink href="https://twitter.com/ArnaudD" alt="Twitter Profile">
                <TwitterIcon />
              </ExtLink>
              <ExtLink href="mailto:arnaud.didry@gmail.com" alt="Email">
                <EmailIcon />
              </ExtLink>
              <ExtLink href="tel:+33687035059" alt="Phone">
                <PhoneIcon />
              </ExtLink>
            </div>
            <p>
              Développeur web curieux et passionné, j'aime produire des applications qui apportent
              de la valeur aux utilisateurs tout en étant agréables à utiliser. Spécialiste du
              front, je suis également à l'aise côté back et sur la partie devops. Il m'arrive aussi
              de prendre le rôle de designer si besoin.
            </p>
          </header>
        </Wrapper>
      </Highlight>
      <main>
        <Wrapper>
          <h2>Compétences</h2>
          <p>
            Développement, Devops, Expertise et lead technique, Architecture technique, Gestion de
            projet, UI/UX
          </p>
        </Wrapper>
        <Wrapper>
          <h2>Valeurs</h2>
          <p>
            Passion, Curiosité, Qualité, Lean, Release fast/fail fast, Less is more, Transparence,
            Empathie{' '}
          </p>
        </Wrapper>
        <Wrapper>
          <h2>Expérience</h2>
          <h3>
            CTO @ <ExtLink href="https://www.fizix.io/">Fizix</ExtLink>
          </h3>
          <Period duration="4 ans" from="septembre 2015" to="août 2019" />
          <p>Coaching Sportif à Domicile et en Entreprise</p>
          <p>
            Nous avons avons développé un ensemble d'applications centrées autour de nos coachs et
            du carnet sportif des pratiquants, qu'ils soient salariés ou particuliers. Comme tout
            CTO de startup en early stage, mes missions sont très variées.
          </p>
          <h4>Missions :</h4>
          <ul>
            <li>Conception, UI/UX et développement</li>
            <li>Suivi de la qualité et de la dette technique, revues de code</li>
            <li>
              Gestion de l'infrastructure. Auto-hébergée dans un premier temps, puis migration sur
              Docker Cloud (anciennement Tutum) et maintenant sur Google Cloud (Kubernetes).
            </li>
            <li>
              Amélioration de l'expérience développeur : mise en place d'intégrations continues,
              automatisation des process de développement et de déploiement, documentation technique
            </li>
            <li>
              Rapatriement des données métier depuis un CRM externe (Migration de SQL Server vers
              PostgreSQL)
            </li>
            <li>Management de l'équipe technique (2 développeurs)</li>
            <li>Gestion de la roadmap, des estimations et des releases</li>
            <li>Mise en place d'une base de connaissances interne</li>
            <li>
              Création (occasionnelle) de contenus graphiques pour différents supports de
              communication (landing pages, présentations, newsletter, kakémono, et même lycras !)
            </li>
          </ul>
          <h4>Applications développées (Toutes sous forme de Single Page App) :</h4>
          <ul>
            <li>Marketplace pour la réservation de coachs sportifs à domicile</li>
            <li>Application coach</li>
            <li>Carnet sportif partagé</li>
            <li>
              Plateforme en marque blanche pour les cours de sport en entreprise : Réservations,
              notifications, paiement, etc
            </li>
            <li>Fizix Live : Coaching sportif en live par webcam</li>
            <li>
              Administration pour notre service client : Gestion des coachs, des planings et de la
              facturation, algorithme de recherche de coachs par secteur géographique
            </li>
          </ul>
          <h4>Technos :</h4>
          <p>NodeJS, React, GraphQL, PostgreSQL, Docker, Kubernetes</p>

          <h4>Outils :</h4>
          <p>
            React-Admin, MaterialUI, Metabase, Sequelize, Epilogue, Apollo GraphQL, Webpack,
            GatsbyJS
          </p>

          <h4>Services :</h4>
          <p>
            Auth0, AWS, Google Cloud, Intercom, Stripe, Sendgrid, Sentry, Tokbox, Atlassian,
            CircleCI, Twilio
          </p>

          <div className={styles.Notes}>
            <p>
              Suite à des difficultés financières Fizix a du fermer ses portes et mon expérience
              s'est arrêtée là.
            </p>
            <p>
              Malgré la déception de n'avoir pas pu aller jusqu'au bout de nos ambitions, je garde
              de très bons souvenirs de cette expérience et suis fier de ce que nous avons réalisé.
              La satisfaction de nos utilisateurs était le signe que notre produit répondait à un
              besoin et qu'il y répondait bien.
            </p>
            <p>
              C'était aussi une expérience enrichissante sur le plan technique de part l'ampleur et
              la diversité des développements réalisés, et celà avec peu de moyens.
            </p>
          </div>
        </Wrapper>
        <Gallery images={data.fizixScreenshots.edges} />

        <Wrapper>
          <h3>
            Lead Developer @ <ExtLink href="https://www.clicrdv.com/">ClicRDV</ExtLink>
          </h3>
          <Period duration="4 ans" from="septembre 2011" to="août 2015" />
          <p>Agenda et prise de rendez-vous en ligne</p>
          <p>
            ClicRDV a pour client des professionnels de secteurs très divers (santé, beauté,
            garages, banques, etc), un agenda en ligne ainsi que des sites permettant à leur clients
            de réserver des rendez-vous.
          </p>
          <p>
            Parce que l'agenda est souvent un des outil central de nos clients, la haute
            disponibilité du service de prise de rendez-vous et de l'agenda est primordiale.
            Celui-ci peut d'ailleurs fonctionner hors ligne en cas de coupure internet grâce aux
            technos AppCache et IndexedDB.
          </p>
          <ul>
            <li>Frontend (Javascript, YUI3, Angular / Node / React / Webpack)</li>
            <li>Backend (Ruby on Rails, MySQL, MongoDB, Redis, Docker)</li>
            <li>Tech Lead</li>
            <li>Support API / Tech pour de grands comptes</li>
          </ul>

          <div className={styles.Notes}>
            <p>
              À l'époque où IE6 régnaient sur le web, où les standards de stockage hors ligne n'en
              étaient qu'à leurs balbutiements et où les outils d'industrialisation du dev front
              n'existaient pas, prendre le parti de développer une SPA fonctionnant hors ligne et
              d'y stocker des dizaines de méga-octets de données (l'équivalent de centaines de
              milliers de rdv et plusieurs milliers de fiches client) côté navigateur tout en
              assurant une synchronisation avec un serveur était très ambitieux.
            </p>
            <p>
              Ce pari osé a pourtant été réussi haut la main grâce à une équipe talentueuse.
              Rigueur, ingéniosité et attention de l'utilisateur ont été les ingrédients de cette
              réussite. J'ai beaucoup apris sur ces points grâce à{' '}
              <ExtLink href="https://www.linkedin.com/in/maximerety/">Max</ExtLink>,{' '}
              <ExtLink href="https://www.linkedin.com/in/ericabouaf/">Éric</ExtLink> et{' '}
              <ExtLink href="https://www.linkedin.com/in/antoine-puymirat-030b1213/">
                Antoine
              </ExtLink>{' '}
              qui sont devenus mes mentors et l'étaient pour beaucoup dans l'équipe.
            </p>
            <p>
              J'ai aussi pu échanger quotidiennemt en anglais avec une équipe internationnale (UK,
              Allemagne). Quitter cette belle équipe n'a pas été facile.
            </p>
          </div>
        </Wrapper>

        <Wrapper>
          <h3>
            Développeur Web @{' '}
            <ExtLink href="http://univ-avignon.fr/universite/organisation/services-centraux/direction-operationnelle-des-systemes-d-information-dosi--1422.kjsp">
              <abbr title="Direction Opérationnelle des Systèmes d'informations">DOSI</abbr> -
              Université d'Avignon
            </ExtLink>
          </h3>
          <Period duration="3 ans" from="septembre 2008" to="août 2011" />
          <p>
            Développement d'applications web pour le personnel administratif et pour les étudiants
          </p>
          <p>1 an en alternance puis 2 ans sous contrat.</p>
          <ul>
            <li>Développement d'applications web basées sur symfony (v1.x)</li>
            <li>Développement de plugins symfony partagés entre les différents projets</li>
            <li>Développement d'applications libres (http://github.com/UAPV)</li>
            <li>Évaluations et intégrations d'applications libres dans le SI.</li>
          </ul>
          <div className={styles.Notes}>
            <p>
              Expérience fortement marquée par l'open-source. J'ai eu l'occassion d'apporter des
              correctifs sur plusieurs logiciels libres :{' '}
              <ExtLink href="http://propelorm.org/">Propel ORM</ExtLink>,{' '}
              <ExtLink href="https://www.horde.org/">Horde</ExtLink>,{' '}
              <ExtLink href="https://glpi-project.org/">GLPI</ExtLink>,{' '}
              <ExtLink href="https://obm.org/">OBM</ExtLink>.
            </p>
            <p>
              Parmis les projets réalisés, j'ai été en charge du développement d'une application web
              de partage de fichier (<ExtLink href="https://github.com/FileZ/FileZ">FileZ</ExtLink>,
              similaire à <ExtLink href="https://wetransfer.com/">WeTransfer</ExtLink>) qui a été
              installée par des dizaines d'organismes publics (université, régions) et privés. J'ai
              d'ailleurs reçu plusieurs contributions extérieures dont des traductions (8 langues,
              dont l'Espranto !). L'effet WOW de l'époque était d'avoir une barre de progression
              lors de l'upload (à l'aide de Flash malheureusement).
            </p>
          </div>
        </Wrapper>

        <Wrapper>
          <h3>Designer et Développeur frontend @ MesResas.com</h3>
          <Period duration="1 an et 4 mois" from="octobre 2007" to="janvier 2009" />
          <p>Mission réalisée en parallèle de mes études</p>
          <ul>
            <li>Design des interfaces, réflexions sur l'expérience utilisateur</li>
            <li>Développement d'agendas partagés (AJAX, jQuery)</li>
            <li>Intégration à l'application PHP (Framework Zend)</li>
          </ul>
        </Wrapper>
        <Wrapper>
          <h3>Développeur Web @IPSORESO</h3>
          <Period duration="5 mois" from="avril 2005" to="août 2005" />
          <p>Développement d'applications Web pour des établissements de santé.</p>
          <ul>
            <li>Migration d'une application existante (Delphi) vers un portail web en PHP.</li>
            <li>Création d’un webservice SOAP permettant la communication avec un pocket PC.</li>
            <li>Génération de graphiques</li>
            <li>Création de workflow</li>
            <li>Interfaçage avec un annuaire LDAP</li>
          </ul>
        </Wrapper>
        <Wrapper>
          <h2>Side projects</h2>
          <h3>
            SndcldTV - <ExtLink href="https://github.com/ArnaudD/SndcldTV">Github</ExtLink>
          </h3>
          <p>Player Soundcloud pour Android TV</p>
          <h4>Technos :</h4>
          <p>React Native, Firebase (pour le login)</p>
          <h3>
            FlatFinder - <ExtLink href="https://github.com/ArnaudD/FlatFinder">Github</ExtLink>
          </h3>
          <p></p>
          <h4>Technos :</h4>
          <p>NodeJS, Puppeteer, React (pour le formatage des mails)</p>
        </Wrapper>
        <Wrapper>
          <h2>Formations</h2>
        </Wrapper>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query {
    fizixScreenshots: allFile(
      filter: { relativePath: { glob: "fizix/*.png" } }
      sort: { fields: relativePath }
    ) {
      edges {
        node {
          publicURL
          small: childImageSharp {
            fixed(width: 160, height: 160) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
