import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const Highlight = ({ children, bottomShadow }) => (
  <div className={bottomShadow ? styles.HighlightFromTop : styles.HighlightFromBottom}>
    {children}
  </div>
);

Highlight.propTypes = {
  children: PropTypes.node.isRequired,
  bottomShadow: PropTypes.bool,
};

export default Highlight;
