import React, { useState } from 'react';

import Img from 'gatsby-image';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import styles from './index.module.css';

const Gallery = ({ images }) => {
  if (!images) return null;

  const [currentIndex, setCurrentIndex] = useState(null);
  const imagesCount = images.length;

  return (
    <>
      <div className={styles.Carrousel}>
        {images.map(({ node }, index) => (
          <a key={index} onClick={() => setCurrentIndex(index)}>
            <Img fixed={node.small.fixed} className={styles.Image} />
          </a>
        ))}
      </div>

      {currentIndex !== null && (
        <Lightbox
          mainSrc={images[currentIndex].node.publicURL}
          nextSrc={images[(currentIndex + 1) % imagesCount].node.publicURL}
          prevSrc={images[(currentIndex + imagesCount - 1) % imagesCount].node.publicURL}
          onCloseRequest={() => setCurrentIndex(null)}
          onMovePrevRequest={() => setCurrentIndex((currentIndex + imagesCount - 1) % imagesCount)}
          onMoveNextRequest={() => setCurrentIndex((currentIndex + 1) % imagesCount)}
        />
      )}
    </>
  );
};

export default Gallery;
