import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const Wrapper = ({ children, className }) => (
  <div className={classnames(styles.Wrapper, className)}>{children}</div>
);

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Wrapper;
